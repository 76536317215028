.header-brand .brand-type {
  color: #4d67a9;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}

.night .header-container {
  background-color: #2d2d2d;
}
.night nav {
  color: #ddd;
}
.night .mode-switcher button {
  color: #ddd;
}
.night .header-actions .mdl-button {
  color: #ddd;
}
.night .brand-type {
  color: #ddd;
}

header {
  height: 64px;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: all ease-in-out 0.25s;
  width: 100%;
  z-index: 3;
}
header.down, header.up {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
}
header.down {
  top: -70px;
}

.header-container {
  background-color: #fff;
  height: 100%;
}

.header-brand {
  display: flex;
}
.header-brand .brand-symbol {
  align-self: flex-start;
  box-sizing: border-box;
  display: flex;
  height: 64px;
  padding: 16px 0;
  width: 32px;
}
.header-brand .brand-symbol img {
  width: 100%;
}
.header-brand .brand-type {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  height: 64px;
  line-height: 18px;
  margin-left: 8px;
  margin-right: 16px;
  max-width: 100px;
  text-decoration: none;
  transition: all ease-in-out 0.35s;
}
@media (min-width: 600px) {
  .header-brand .brand-type {
    margin-left: 16px;
  }
}
@media (min-width: 1280px) {
  .header-brand .brand-type {
    font-size: 20px;
    line-height: 25px;
    max-width: none;
  }
}

.open {
  height: 52vh;
}
@media (orientation: landscape) {
  .open {
    height: 100vh;
  }
}
.open .nav-toggle .burger {
  position: absolute;
}
.open .nav-toggle .burger.top, .open .nav-toggle .burger.bottom {
  left: -2px;
  top: -3px;
}
.open .nav-toggle .burger.top {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.open .nav-toggle .burger.middle {
  display: none;
}
.open .nav-toggle .burger.bottom {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

nav {
  align-items: flex-start;
  box-sizing: border-box;
  color: #4d67a9;
  display: flex;
  flex-direction: column;
  height: auto;
  position: absolute;
  top: 64px;
}
@media screen and (max-width: 480px) {
  nav {
    box-shadow: inset 0 -16px 25px -16px #000;
    display: inline;
    height: calc(100% - 64px);
    left: 0;
    overflow-y: scroll;
    padding: 5px 32px;
    width: 100%;
  }
}
@media (min-width: 1310px) {
  nav {
    align-items: center;
    flex-direction: row;
    font-size: 14px;
    height: 100%;
    position: static;
  }
}
nav a,
nav span {
  align-items: center;
  box-sizing: border-box;
  color: inherit;
  display: flex;
  font-size: 1.1em;
  font-weight: 400;
  height: 64px;
  padding: 12px 0;
  position: relative;
  text-decoration: none;
  transition: color ease-in-out 0.25s;
}
@media (min-width: 1310px) {
  nav a,
nav span {
    padding: 12px;
  }
  nav a:after,
nav span:after {
    background-color: #4d67a9;
    bottom: 0;
    content: "";
    height: 4px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    transition: width cubic-bezier(0.4, 0, 1, 1) 0.2s;
    width: 0;
  }
  nav a.active:after, nav a:hover:after,
nav span.active:after,
nav span:hover:after {
    width: 100%;
  }
}

.header-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
}
.header-actions .mdl-button {
  color: #4d67a9;
}
.header-actions .mdl-button.remote-support {
  background-color: #4d67a9;
  color: rgba(255, 255, 255, 0.87);
  display: none;
}
.header-actions .mdl-button.remote-support:hover {
  background-color: #4d67a9;
}
@media (min-width: 610px) {
  .header-actions .mdl-button.remote-support {
    display: inline-block;
  }
}
@media screen and (max-width: 480px) {
  .header-actions .customer-portal,
.header-actions .remote-support {
    display: none;
  }
}

.nav-toggle {
  align-items: center;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  height: 64px;
  width: 28px;
}
.nav-toggle .burger-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.nav-toggle .burger-container .burger {
  background-color: #4d67a9;
  border-radius: 2px;
  height: 3px;
  margin: 2px;
  transition: all ease-in-out 0.15s;
  width: 100%;
}
@media (min-width: 1310px) {
  .nav-toggle {
    display: none;
  }
}

.mode-switcher {
  align-items: center;
  align-self: flex-start;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 64px;
  margin-right: 8px;
}
.mode-switcher:hover {
  background-color: transparent;
}
.mode-switcher button {
  color: #4d67a9;
}