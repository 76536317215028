.banner {
  background-position: center calc(50% + 32px);
  background-repeat: no-repeat;
  background-size: cover;
  height: 55vh;
  margin: 0;
  position: relative;
  width: 100%;
}
#home .banner {
  background-position: 61% calc(50% + 32px);
  height: 80vh;
}
.banner span {
  bottom: 32px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 48px;
  left: 32px;
  line-height: 64px;
  position: absolute;
  right: 32px;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}
@media (min-width: 1544px) {
  .banner span {
    bottom: 144px;
    font-size: 56px;
    left: calc((100% - 1480px) / 2);
    max-width: 25%;
    right: 0;
  }
}