.night .paragraph .paragraph-heading {
  color: #a1b0d4;
}
.night .paragraph p {
  color: rgba(255, 255, 255, 0.87);
}

.paragraph {
  font-family: "Open Sans" sans-serif;
}
.paragraph .paragraph-heading {
  color: #354774;
  margin-bottom: 4px;
}
.paragraph p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.2em;
  letter-spacing: normal;
  line-height: 28px;
  white-space: pre-wrap;
}
@media (min-width: 960px) {
  .paragraph p {
    font-size: 1.3em;
    letter-spacing: 0.2px;
    line-height: 36px;
  }
}