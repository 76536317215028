.mode-transition {
  display: none;
  height: 100vh;
  position: fixed;
  width: 100vw;
  z-index: 10;
}
.mode-transition.transition {
  -webkit-animation: 500ms bg-frames;
          animation: 500ms bg-frames;
  display: block;
}

@-webkit-keyframes bg-frames {
  0% {
    background-color: #000;
  }
  10% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes bg-frames {
  0% {
    background-color: #000;
  }
  10% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    background-color: transparent;
  }
}