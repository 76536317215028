.call-to-action .section-container .caption {
  color: #4d67a9;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}

.night .call-to-action {
  background-color: #40568d;
  color: rgba(255, 255, 255, 0.87);
}

.call-to-action {
  background-color: #4d67a9;
  color: rgba(255, 255, 255, 0.87);
  margin: 24px 0 0;
  order: 0;
  padding: 16px 0;
}
@media (min-width: 960px) {
  .call-to-action {
    margin: 64px 0 0;
    padding: 48px 0;
  }
}
.call-to-action .section-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.call-to-action .section-container .avatar {
  -webkit-filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.14));
          filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.14));
  margin: 0 0 8px;
  max-height: 100px;
}
@media (min-width: 960px) {
  .call-to-action .section-container .avatar {
    margin: 8px 16px 8px 0;
  }
}
.call-to-action .section-container .caption {
  color: rgba(255, 255, 255, 0.87);
}
.call-to-action .section-container .caption.with-author {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  line-height: 1.4em;
  margin-top: 0;
}
@media (min-width: 960px) {
  .call-to-action .section-container .caption.with-author {
    font-size: 1.33em;
    line-height: 1.5em;
    margin: 0;
  }
}
.call-to-action .section-container .action {
  background-color: #f7931e;
  color: #000;
}
@media (min-width: 960px) {
  .call-to-action .section-container {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}