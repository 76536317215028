.primary-container {
  background-color: #ebebeb;
  display: flex;
  flex-flow: column nowrap;
}
.primary-container.night {
  background-color: #191919;
}

.section-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex: 1;
  margin: 0 auto;
  padding: 0 32px;
  width: 100%;
}
.section-container.no-flex {
  display: block;
}
.section-container.row {
  flex-direction: row;
}
.section-container.column {
  align-items: flex-start;
  flex-direction: column;
}
@media (min-width: 1500px) {
  .section-container {
    width: 1480px;
  }
}

.flex {
  flex: 1;
}

#root {
  min-height: 100vh;
}
#root > *,
#root .primary-container {
  min-height: inherit;
}

main {
  display: flex;
  flex-direction: column;
}