.night .action {
  color: rgba(255, 255, 255, 0.87);
}

.button-wrapper {
  display: inline-block;
}

.action {
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  min-width: 88px;
  text-decoration: none !important;
  white-space: nowrap;
}
.action i {
  font-size: 16px;
  margin-right: 4px;
  width: 16px;
}