section .subheading, section .heading {
  color: #4d67a9;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}

.night section.odd {
  background-color: #191919;
}
.night section.even {
  background-color: #202020;
}
.night section .subheading {
  color: rgba(255, 255, 255, 0.4);
}
.night section .split-pane .visualization .visualization-image {
  color: rgba(255, 255, 255, 0.87);
}

section {
  overflow-x: hidden;
  padding: 32px 0;
}
@media (min-width: 960px) {
  section {
    padding: 64px 0;
  }
}
section:first-child {
  padding-top: 148px;
}
section.even {
  background-color: #f2f2f2;
}
section.odd {
  background-color: #ebebeb;
}
section .heading {
  margin-bottom: 8px;
  max-width: 100%;
}
section .heading + .subheading {
  margin-top: 0;
}
section .subheading {
  color: rgba(0, 0, 0, 0.4);
  max-width: 100%;
}
section .split-pane {
  display: flex;
  flex-direction: column;
  width: 100%;
}
section .split-pane .visualization {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center;
  order: 1;
}
section .split-pane .visualization .visualization-image {
  align-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-flow: row;
  height: 100%;
  justify-content: center;
  max-height: 600px;
  min-height: 300px;
  width: 100%;
}
section .split-pane .visualization .visualization-image:not(.map) {
  -webkit-filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0 3px 1px rgba(0, 0, 0, 0.12));
          filter: drop-shadow(0 1px 5px rgba(0, 0, 0, 0.2)) drop-shadow(0 2px 2px rgba(0, 0, 0, 0.14)) drop-shadow(0 3px 1px rgba(0, 0, 0, 0.12));
}
section .split-pane .copy {
  order: 2;
}
@media (min-width: 960px) {
  section .split-pane {
    flex-direction: row;
  }
  section .split-pane .visualization {
    padding: 0 48px;
  }
  section .split-pane:not(.reverse) .visualization {
    order: 2;
  }
  section .split-pane:not(.reverse) .copy {
    order: 1;
  }
}