.night .image-card {
  background-color: #2d2d2d;
}

#gallery-section .section-container {
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
#gallery-section .section-container .heading {
  margin-bottom: 32px;
  text-align: center;
  width: 100%;
}

.gallery {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}

.image-card {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  height: 300px;
  margin: 8px;
  padding: 16px;
  position: relative;
  width: 400px;
}
.image-card:after {
  bottom: 0;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all ease-in-out 0.3s;
}
.image-card:hover:after {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12);
}
.image-card .image {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}