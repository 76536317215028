.night .selected-image {
  background-color: rgba(0, 0, 0, 0.95);
}
.night .selected-image .close,
.night .selected-image .next,
.night .selected-image .prev {
  background-color: #444;
}
.night .selected-image .close:hover,
.night .selected-image .next:hover,
.night .selected-image .prev:hover {
  background-color: #666;
}

.selected-image {
  background-color: rgba(0, 0, 0, 0.95);
  bottom: 0;
  color: #fff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 10;
}
.selected-image .close,
.selected-image .next,
.selected-image .prev {
  background-color: #444;
  border-radius: 50%;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  height: 40px;
  padding: 8px;
  position: absolute;
  transition: all ease-in-out 0.3s;
  width: 40px;
}
.selected-image .close:hover,
.selected-image .next:hover,
.selected-image .prev:hover {
  background-color: #666;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}
.selected-image .next,
.selected-image .prev {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.selected-image .image-container {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: absolute;
}
.selected-image .image-container img {
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  max-height: 100%;
  max-width: 100%;
}
.selected-image .close {
  right: 4px;
  top: 4px;
}
.selected-image .next {
  right: 4px;
}
.selected-image .prev {
  left: 4px;
}
.selected-image .image-container {
  bottom: 48px;
  left: 48px;
  right: 48px;
  top: 48px;
}
@media (min-width: 600px) {
  .selected-image .close {
    right: 8px;
    top: 8px;
  }
  .selected-image .next {
    right: 8px;
  }
  .selected-image .prev {
    left: 8px;
  }
  .selected-image .image-container {
    bottom: 56px;
    left: 56px;
    right: 56px;
    top: 56px;
  }
}
@media (min-width: 960px) {
  .selected-image .close {
    right: 16px;
    top: 16px;
  }
  .selected-image .next {
    right: 16px;
  }
  .selected-image .prev {
    left: 16px;
  }
  .selected-image .image-container {
    bottom: 72px;
    left: 72px;
    right: 72px;
    top: 72px;
  }
}
@media (min-width: 1280px) {
  .selected-image .close {
    right: 32px;
    top: 32px;
  }
  .selected-image .next {
    right: 32px;
  }
  .selected-image .prev {
    left: 32px;
  }
  .selected-image .image-container {
    bottom: 104px;
    left: 104px;
    right: 104px;
    top: 104px;
  }
}