html {
  font-size: 14px;
}

body {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0;
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.mdl-button--raised {
  overflow: visible;
  position: relative;
}
.mdl-button--raised:after {
  bottom: 0;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  content: "";
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity ease-in-out 225ms;
}
.mdl-button--raised:hover:after {
  opacity: 1;
}