@charset "UTF-8";
.night .list {
  color: rgba(255, 255, 255, 0.87);
}
.night .list-heading {
  color: #9ca6c0;
}
.night ul.list li::before {
  color: #9ca6c0;
}

.list {
  color: rgba(0, 0, 0, 0.87);
  font-family: "Open Sans" sans-serif;
  font-size: 1.3em;
}

.list-heading {
  color: #6478ab;
  font-size: 20px;
}

ul.list {
  list-style: none;
  padding: 0;
}
ul.list li {
  padding-left: 1em;
  text-indent: -0.6em;
}
ul.list li::before {
  color: #6478ab;
  content: "• ";
}