.night .matrix {
  color: rgba(255, 255, 255, 0.87);
}

.matrix {
  border-collapse: collapse;
  font-size: 1em;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}
@media (min-width: 768px) {
  .matrix {
    font-size: 1.3em;
    line-height: 48px;
  }
}

@media (min-width: 768px) {
  .matrix-header {
    font-size: 1.5em;
  }
}

.matrix-body__caption {
  text-align: left;
}

.matrix-body tr {
  border-top: 1px solid rgba(0, 0, 0, 0.36);
}

.matrix-header__tier {
  width: 50px;
}
@media (min-width: 768px) {
  .matrix-header__tier {
    width: 120px;
  }
}

.matrix-body__tier .material-icons {
  margin: 4px 0;
}