.brand .title, .mdl-mega-footer .mdl-mega-footer__heading {
  color: #4d67a9;
  font-family: "Quicksand", sans-serif;
  font-weight: 300;
}

.night .mdl-mega-footer {
  background-color: #2d2d2d;
}
.night .mdl-mega-footer a {
  text-decoration: none;
  color: rgba(217, 217, 217, 0.87);
}
.night .mdl-mega-footer a:hover {
  color: rgba(255, 255, 255, 0.87);
  text-decoration: underline;
}
.night .mdl-mega-footer .mdl-mega-footer__bottom-section .copyright {
  color: rgba(255, 255, 255, 0.87);
}

.mdl-mega-footer {
  background-color: #e3e3e3;
  padding: 16px 0;
}
.mdl-mega-footer a {
  text-decoration: none;
  color: rgba(38, 38, 38, 0.87);
}
.mdl-mega-footer a:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: underline;
}
.mdl-mega-footer .mdl-mega-footer__heading {
  font-size: 20px;
  line-height: 25px;
}
.mdl-mega-footer .mdl-mega-footer__bottom-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
@media (min-width: 850px) {
  .mdl-mega-footer .mdl-mega-footer__bottom-section {
    align-items: center;
    flex-direction: row;
  }
  .mdl-mega-footer .mdl-mega-footer__bottom-section .copyright {
    text-align: left;
  }
}
.mdl-mega-footer .mdl-mega-footer__bottom-section .copyright {
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.mdl-mega-footer .mdl-mega-footer__bottom-section nav {
  box-shadow: none;
  height: auto;
  overflow: visible;
  padding: 8px 0;
}
@media (max-width: 599px) {
  .mdl-mega-footer .mdl-mega-footer__bottom-section nav {
    padding: 0;
  }
  .mdl-mega-footer .mdl-mega-footer__bottom-section nav a {
    margin: 0;
  }
}
.mdl-mega-footer nav {
  flex-flow: row nowrap;
  position: static;
}
.mdl-mega-footer nav a {
  height: auto;
  margin: 0 12px;
  padding: 0;
}
.mdl-mega-footer nav a.active:after, .mdl-mega-footer nav a:hover:after {
  display: none;
}

.brand {
  align-items: center;
  display: flex;
}
.brand .logo {
  height: 15px;
  margin-right: 8px;
}
.brand .title {
  font-size: 20px;
  line-height: 25px;
  margin-right: 8px;
  text-transform: uppercase;
}

.social-link {
  align-items: center;
  display: flex;
}
.social-link img {
  margin-right: 5px;
  width: 15px;
}

.sage-partner {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  margin-bottom: 8px;
  transition: all ease-in-out 100ms;
  width: 100%;
}
.sage-partner:hover {
  -webkit-filter: none;
          filter: none;
}

.other-partner {
  border: 3px solid #9d9d9d;
  border-radius: 16px;
  box-sizing: border-box;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  margin-bottom: 8px;
  transition: all ease-in-out 100ms;
  width: 100%;
}
.other-partner:hover {
  -webkit-filter: none;
          filter: none;
}