.night .card {
  background-color: #3a3a3a;
}
.night .card button {
  color: rgba(242, 242, 242, 0.87);
}
.night .card-content.with-image {
  background-color: #555;
}

.card-bar {
  margin: 0;
  padding: 50px;
}

.card-bar-container {
  margin: 0 auto;
  max-width: 1480px;
}

.card-container {
  display: inline-block;
}
@media (max-width: 599px) {
  .card-container {
    width: 100%;
  }
}

.card {
  box-sizing: border-box;
  display: block;
  margin: 8px 16px;
  width: 300px;
}
@media (max-width: 599px) {
  .card {
    margin: 8px 0;
    width: 100%;
  }
}

.card-action .button-wrapper {
  width: 100%;
}
.card-action .button-wrapper .action {
  background-color: transparent;
  border: 0;
  box-shadow: none !important;
  box-sizing: border-box;
  padding: 0 8px;
  width: 100%;
}
.card-action .button-wrapper .action:after {
  opacity: 0 !important;
}
.card-action .button-wrapper .action span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-content {
  background-color: #f7931e;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  height: 200px;
  position: relative;
  width: 100%;
}
.card-content span {
  bottom: 0;
  box-sizing: border-box;
  left: 0;
  padding: 8px 16px;
  position: absolute;
  width: 100%;
}
@media (max-width: 599px) {
  .card-content {
    height: 0;
    padding: 66.6667% 0 0 0;
  }
}
.card-content.with-image {
  background-color: #dedede;
  color: rgba(255, 255, 255, 0.87);
}
.card-content.with-image span {
  background-color: rgba(0, 0, 0, 0.4);
}